<script setup lang="ts"></script>

<template>
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.99985 5.71191C7.27308 5.71191 5.86816 7.18696 5.86816 9.00004C5.86816 10.8131 7.27308 12.2882 8.99985 12.2882C10.7264 12.2882 12.1315 10.8131 12.1315 9.00004C12.1315 7.18696 10.7264 5.71191 8.99985 5.71191Z"
            fill="currentColor"
        />
        <path
            d="M8.99991 0.579102C4.34908 0.579102 0.578857 4.34932 0.578857 9.00015C0.578857 13.651 4.34908 17.4212 8.99991 17.4212C13.6507 17.4212 17.421 13.651 17.421 9.00015C17.421 4.34932 13.6507 0.579102 8.99991 0.579102ZM8.99991 15.0669C5.7409 15.0669 3.08958 12.3455 3.08958 9.00015C3.08958 5.65482 5.7409 2.93338 8.99991 2.93338C12.2588 2.93338 14.9102 5.65482 14.9102 9.00015C14.9102 12.3455 12.2588 15.0669 8.99991 15.0669Z"
            fill="currentColor"
        />
    </svg>
</template>
